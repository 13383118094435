const COS = require('cos-js-sdk-v5');
/**
 *
 * @param {腾讯云的pramas} data
 * @returns
 */
export default function createCOS(data) {
  const {
    bucket, host, region, sessionToken, tmpSecretId, tmpSecretKey, startTime, expiredTime, name, file,
  } = data;

  const config = {
    Region: region,
    Host: host,
    Bucket: bucket,
    Key: name,
    Body: file,
    onProgress: (progressData) => {
      console.log(progressData);
    }
  };
  const client = new COS({
    getAuthorization: (options, callback) => {
      callback({
        TmpSecretId: tmpSecretId,
        TmpSecretKey: tmpSecretKey,
        SecurityToken: sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: expiredTime,
      });
    },
  })
  return new Promise((resolve, reject) => {
    client.putObject(config, (err, data) => {
      console.log('腾讯上传', err, data);
      if (err) {
        reject(err);
      } else {
        resolve(data.Location);
      }
    })
  })
}