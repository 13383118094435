import request from '@/utils/request'
import createCOS from "@/utils/txyunCosUpLoad.js";
import { uploadDocumentFile } from "./data-analyse.js";
import { baseOss } from '../config.js';
import router from "../router/index";
import { computed } from 'vue';
const sn = computed(() => router.currentRoute.value.query.sn || 'default');
export default class CommonApi {
  /** OssInfo */
  static getOssInfo() {
    return request({
      url: '/console/files/credentials',
    });
  }
  /**
 * 上传文件
 * @param {文件file} file
 * @returns {url:"",name:'fileName'}
 */
  static async cosUpload(file, fileName) {
    const getRandomFileName = (name) => {
      return `survey/${sn.value}/${new Date().getTime()}_${Math.floor(
        Math.random() * 1000
      )}_${name}`;;
    };
    const reg = /\\|\/|\?|\？|\*|\"|\“|\”|\'|\‘|\’|\<|\>|\{|\}|\[|\]|\【|\】|\：|\:|\、|\^|\$|\!|\~|\`|\|\\s|\s/g;
    const name = fileName ? fileName : getRandomFileName(file?.name?.replace(reg, '') ?? '' ?? `${new Date().getTime()}.png`);
    const { data } = await CommonApi.getOssInfo();
    const param = {
      region: data.Region,
      host: data.Host,
      sessionToken: data.sessionToken,
      tmpSecretId: data.tmpSecretId,
      tmpSecretKey: data.tmpSecretKey,
      bucket: data.Bucket,
      startTime: data.startTime,
      expiredTime: data.expiredTime,
      name: `${data.prefix}/${name}`,
      file,
    };
    try {
      const location = await createCOS(param);
      console.log('888', baseOss, name);
      return {
        // url: `${baseOss}/${name}`,
        url: `https://${location}`,
        name
      };
    } catch (error) {
      console.log(error);
      return {
        url: '',
        name: ''
      };
    }
  }
  /**
   * 上传文件到阿里云和文档库
   * @param {文件file} file
   * @param {问卷id} sn
   * @param {1=问卷设计,2=数据分析,3=问卷预览,4=数据明细,5=图表分析,6=高级分析,7=市场模拟} importType
   * @returns {url:"",name:'fileName'}
   */
  static async docuHousecosUpload(file, sn, importType, startFileName = 'survey') {
    const reg = /\\|\/|\?|\？|\*|\"|\“|\”|\'|\‘|\’|\<|\>|\{|\}|\[|\]|\【|\】|\：|\:|\、|\^|\$|\!|\~|\`|\|\\s|\s/g;
    const fileName = `${startFileName}/${new Date().getTime()}_${Math.floor(
      Math.random() * 1000
    )}_${file?.name?.replace(reg, '') ?? '' ?? `${new Date().getTime()}.png`}`;;
    try {
      const result = await this.cosUpload(file, fileName, callBack);
      uploadDocumentFile({
        url: JSON.stringify([
          { name: file.name, url: result.url, size: file.size },
        ]),
        parent_sn: sn?.value || sn,
        import_type: importType || 1,
      });
      return result
    } catch (error) {
      console.log(error);
    }
  }
}
