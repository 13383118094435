import request from '@/utils/request'
import qs from 'qs'

/**
 * 获取数据明细列表
 */
export function getParticularList(sn, params) {
  console.log(params);
  return request({
    url: `/console/surveys/${sn}/answers?${params}`,
    method: 'get',
  })
}
/*
* 数据明细删除
* */
export function delParticularItem(survey_sn, answer_sn) {
   return request({
     url: `/console/surveys/${survey_sn}/answers/${answer_sn}/recycle`,
     method: 'delete'
   })
}

/*
* 数据明细下载题型导出
* */

export function downloadAnswerFile(sn, question_index, params) {
  let url = params ? `/console/surveys/${sn}/answers_file_download/${question_index}?${params}` : `/console/surveys/${sn}/answers_file_download/${question_index}`
  return request({
    url,
    method: 'get',
  })

}

/*
* 文件上传题图片下载
* */

export function getAnswerFileExport(params={}) {
  return request({
    url:`/console/answer/${params.answer}/question/${params.questionIndex}/file_export`,
    method: 'GET',
    params
  })

}

/*
* 数据明细下载题型导出
* */

export function getAnswers_file_download(sn, question_index, params) {
  return request({
    url:`/console/surveys/${sn}/answers_file_download/${question_index}`,
    params,
    method: 'GET',
  })

}
// 图表分析上传题下载全部附件
export function downloadAllFile(sn, question_index, data) {
  return request({
    url: `/console/surveys/${sn}/chart_analysis/${question_index}/file_download`,
    data,
    method: 'post',
  })
}

/**
 * 文件题 签名题下载
 */

export function downloadFileSheet(sn, answer, questionIndex) {
  return request({
    url: `/console/surveys/${sn}/answer/${answer}/question/${questionIndex}/file_export`,
    method: 'GET',
  })
}



/**
 *
 * 数据详情
 */
export function getParticularDetail(sn, id, params) {
  return request({
    url: `/console/surveys/${sn}/answers/${id}`,
    method: 'get',
    params
  })
}

// 配置列
export function editColumnConfig(sn, data) {
  return request({
    url: `/console/surveys/${sn}/answers`,
    method: 'put',
    data
  })
}

// 下载
export function answers_download(sn, params) {
  return request({
    url: `/console/surveys/${sn}/answers_download`,
    method: 'POST',
    data:params
  })
}
export function answerExportPdf(sn, data={}) {
  return request({
    url: `/console/surveys/${sn}/answers_download`,
    method: 'POST',
    data
  })
}

// 导出

export function answers_export(sn, data) {
  return request({
    url: `console/surveys/${sn}/answers_export`,
    method: 'post',
    data
  })
}


/**
 * 单题分析
 */
 export function getDiagramAnalysis(sn, data) {
  return request({
    url: `/console/surveys/${sn}/chart_analysis`,
    method: 'post',
    data
  })
}


export function getSingleDiagramInfo(sn, data) {
  return request({
    url: `/console/surveys/${sn}/chart_analysis`,
    method: 'post',
    data,
  })
}
/**
 * 移动，删除图表
 * @param sn
 * @param data
 * @returns {*}
 */
export function sliceDiagram(sn, data) {
  return request({
    url: `/console/surveys/${sn}/chart_analysis`,
    method: "put",
    data
  });
}


/**
 * 导出图表
 */

export function exportChart(sn, data) {
  return request({
    url: `/console/surveys/${sn}/chart_analysis/export`,
    method: 'post',
    data
  })
}


// 上传文件

export function uploadDocumentFile(data) {
  return request({
    url: `/console/document_import`,
    method: 'post',
    data
  })
}

